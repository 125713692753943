@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Inter";
    src: url("./fonts/Inter/Inter-VariableFont_slnt\,wght.ttf");
}
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}